.worklog-modal-button{
    background-color: var(--accent-colors-blue);
    font-size: 0.75rem;
    color: #fff;
    width: 27rem;
    border: 1px solid var(--accent-colors-blue);
    border-radius: 3px;
}

.worklog-modal-button-div {
    width: fit-content;
    position: relative;
    text-align: center;
}

.worklog-modal-div{
    background-color: var(--primary-color-1) !important;
}

.worklog-modal-input{
    width: 27rem;
    background-color: var(--primary-color-2);
    border: 1px solid var(--primary-color-2);
    border-radius: 3px;
    height: 2rem;
}

.worklog-modal-text{
    width: 27rem;
    background-color: var(--primary-color-2);
    border: 1px solid var(--primary-color-2);
    height: 6rem;
    border-radius: 3px;
}

.worklog-modal-half{
    width: 12.9rem;
    border-radius: 3px;
    background-color: var(--primary-color-2);
    border: 1px solid var(--primary-color-2);
    height: 2rem;
    display: flex;
}

.worklog-label-half{
    display: block;
}

.worklog-modal-label{
    font-size: 0.7rem;
}

.icon-div{
    position: relative !important;
    float: right !important;
    height: 0px !important;
    width: 0px !important;
}

.worklog-half-div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: fit-content;
}


:focus-visible {
    outline-color: #428df5;
}

.worklog-modal-div{
    text-align: left;
}

.modal-error-message {
    display: none;
}

.modal-error-message[data-error] {
    background-color: var(--signal-light-red);
    display: flex;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    width: 27rem;
}

.modal-error-message-icon {
    height: 1rem;
    width: 1rem;
    fill: var(--signal-red);
}

