:root {
    --primary-color-1: #2D3740;
    --primary-color-2: #343F4A;
    --primary-color-3: #45505A;
    --primary-color-4: #B9C2C7;
    --primary-color-5: #FFFFFF;

    --active-color-1: #20272E;
    --active-color-2: #4E5965;
    --active-color-blue: #005ABD;
    --active-color-red: #A92121;
    --active-color-green: #377521;

    --accent-colors-blue: #1277E6;
    --accent-colors-orange: #FF4600;

    --accent-colors-blue-light: rgba(18, 120, 230, 0.3);

    --signal-green: #5CBF37;
    --signal-yellow: #F4A830;
    --signal-red: #FA3333;

    --signal-light-green: rgba(91, 191, 55, 0.3);
    --signal-light-yellow: rgba(244, 169, 48, 0.3);
    --signal-light-red: rgba(250, 51, 51, 0.3);
    --signal-light-red-2: rgba(250, 51, 51, 0.7);
    --signal-light-green-2: rgba(91, 191, 55, 0.7);
    --primary-color-3-light: rgba(69, 80, 90, 0.5);


    --main-text-color: #FFF;




    --spacing-1: 0.25rem;
    --spacing-2: 0.5rem;
    --spacing-3: 1rem;
    --spacing-4: 1.5rem;
    --spacing-5: 3rem;

}

.light-mode {
    --primary-color-1: #FFFFFF;
    --primary-color-2: #F5F7F8;
    --primary-color-3: #E9EDF0;
    --primary-color-4: #707E8B;
    --primary-color-5: #2D3740;

    --active-color-1: #E1E7EA;
    --active-color-2: #F0F7F8;

    --primary-color-3-light: rgba(237, 237, 240, 1);;


    --main-text-color: #000;
}