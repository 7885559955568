@import "fonts.css";

.review-arrow_down-icon {
    width: 1rem;
    height: 1rem;
    fill: var(--primary-color-5);
}

.accept-icon, .decline-icon {
    width: 0.75rem;
    height: 0.75rem;
    align-items: center;
}
.accept-icon {
    fill: var(--signal-green);
}

.review-accept-button:hover .accept-icon {
    fill: var(--primary-color-5)!important;
}

.review-accept-button:hover {
    background-color: var(--signal-green);
}

.decline-icon {
    fill: var(--signal-red);
}

.review-reject-button:hover .decline-icon {
    fill: var(--primary-color-5)!important;
}

.review-reject-button:hover {
    background-color: var(--signal-red);
}

.review-accept-reject {
    text-align: right;
    color: var(--primary-color-5);
}

.review-table-cost-center-data, .review-table-project-data, .review-table-end-time, .review-table-data-description, .review-table-data-processor, .review-table-start-time {
    background-color: var(--primary-color-1);
    text-align: left;
}

.review-table-start-time {
    width: 5rem;
}

.review-table-cost-center-data, .review-table-project-data, .review-table-end-time, .review-table-data-processor {
    font-size: 0.7rem;
}

.review-table-cost-center-data, .review-table-project-data, .review-table-end-time, .review-table-data-processor, .review.review-table-accept-reject {
    width: 7rem;
}

.review-table-data-description {
    width: 40rem;
}

.review-table-head-data {
    text-align: left;
    width: auto;
    color: var(--primary-color-5);
}

.review-edit-delete {
    text-align: right;
}

.review-accept-button, .review-reject-button {
    background-color: var(--primary-color-3);
    border: 0;
}

.review-table-data-processor {
    text-align: center;
}

.review-project-costCenter-border, .review-processor-border, .review-table-end-time-border {
    width: fit-content;
    background-color: var(--primary-color-2);
}

.review-project-costCenter-border, .review-table-end-time-border {
    border-radius: 1.375rem;
}

.review-processor-border {
    border-radius: 1.875rem;
    text-align: center;
}

.review-table-cost-center-data,.review-table-project-data, .review-table-data, .review-table-data-description, .review-table-data-processor, .review-table-accept-reject, .review-table-start-time, .review-table-end-time {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.review-main-table {
    width: 100%;
}

.review-task {
    display: flex;
    justify-content: center;
    margin-top: 3.845rem;
}

.review-table-end-time {
    color: var(--accent-colors-blue);
}

.table-head-time {
    padding: 1rem;
}

.choose-user, .choose-project {
    float: right;
}

.choose-user-button, .choose-project-button, .review-button, .export-button{
    height: 2.5rem;
    width: 14rem;
    background-color: var(--primary-color-2);
    border: 0;
    text-align: center;
    margin-left: 1rem;
    border-radius: 0.2rem;
    color: var(--primary-color-5);
}

.choose-user-button:hover, .choose-project-button:hover {
    background-color: var(--accent-colors-blue);
}

.color-dot-review {
    height: 0.625rem;
    width: 0.625rem;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover, .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal-input-fields {
    text-align: left;
}

.input-field-issue-description {
    text-align: left;
}

.issue-description {
    width: 30rem;
    height: 11rem;
    background-color: var(--primary-color-3);
}

.modal-reject-button {
    background-color: var(--accent-colors-blue);
    border: 0;
    width: 16rem;
    height: 3.125rem;
}

.issue-title-input-field {
    background-color: var(--primary-color-3);
    border: 0;
}

.worklog-reject-header {
    font-size: 1.375rem;
    text-align: left;
    color: var(--primary-color-5);
}

.review-reject-subtitle {
    color: var(--primary-color-4);
}

.issue-description-label, .issue-title-label {
    font-size: 0.8rem;
    color: var(--primary-color-4);
}

.cancel-reject-worklog-button {
    background-color: var(--primary-color-3);
    border: 0;
    width: 7.5rem;
    height: 3.125rem;
}

.checkbox-label {
    display: flex;
}

.checkbox-label input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-wrapper {
    float: right;
}

.add-reason-select {
    background-color: var(--primary-color-1);
    color: var(--primary-color-5);
}

.checkbox-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.3rem;
    width: 1.3rem;
    background-color: var(--primary-color-2);
    border-radius: 0.1rem;
    cursor: pointer;
    margin-right: 0.3rem;
}

.checkbox-frame:hover {
    background-color: var(--primary-color-3);
}

.checkbox-frame:active {
    background-color: var(--active-color-2);
}

.checkbox-frame.checked:active {
    background-color: var(--active-color-blue);
}

.checkbox-frame.checked {
    background-color: var(--accent-colors-blue);
}

.checkmark {
    opacity: 0;
    height: 0;
    width: 0;
}

.checkmark.checked {
    opacity: 1;
    fill: white;
    height: 1rem;
    width: 1rem;
}

.reason-delete-button {
    border: 0;
    background: var(--primary-color-3);
}

.delete-reason-icon {
    height: 1rem;
    width: 1rem;
    fill: var(--signal-red);
}

.add-reason-select {
    width: 2rem;
    height: 2rem;
    -webkit-appearance: none;
    background: var(--accent-colors-blue) no-repeat right center;
}