.user-modal {
    height: 40rem;
}

.user-headline {
    font-size: 20px;
    margin-bottom: 0.5rem;
    color: var(--primary-color-5);
    padding: 2rem 0 0 2rem;
}

.user-modal-info {
    color: var(--primary-color-4);
    padding-bottom: 1rem;
    margin-left: 2rem;
}

.user-list-wrapper {
    overflow-y: scroll;
    height: fit-content;
    display: flex;
    flex-direction: column;
    max-height: 33rem;
}

.user-list {
    list-style: none;
    padding: 0 0.7rem 0 0;
}

.user-list-item {
    background-color: var(--primary-color-2);
    height: 3.6rem;
    border-radius: 2.3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-inline: 1.5rem;
    margin: 1rem;
    color: var(--primary-color-5);
}

.user-list-item.selected {
    background-color: var(--accent-colors-blue);
}

.user-list-item:hover:not(.user-list-item.selected) {
    background-color: var(--primary-color-3);
}

.user-list-dot {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
}

.user-list-wrapper::-webkit-scrollbar {
    background-color: var(--primary-color-2);
    border-bottom-right-radius: 0.2rem;
    width: 0.7rem;
}

.user-list-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--primary-color-4);
    border: 0.2rem solid var(--primary-color-2);
}