@import 'color.css';
@import 'fonts.css';

.toggle {
    --width: 6rem;
    --height: 1rem;

    position: relative;
    display: inline-block;
    width: 7rem;
    height: 1.5rem;
    border-radius: var(--height);
    cursor: pointer;
}

.toggle input {
    display: none;
}

.toggle .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    background-color: var(--primary-color-2);
    transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
    content: 'Dark';
    color: var(--primary-color-2);
    text-align: center;
    font-size: var(--font-size-small);
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: calc(var(--width) / 2);
    height: var(--height);
    border-radius: calc(var(--height) / 2);
    background-color: var(--primary-color-5);
    transition: all 0.4s ease-in-out;
}

.toggle input:checked+.slider {
    background-color: var(--primary-color-2);
}

.toggle input:checked+.slider::before {
    content: 'Light';
    font-size: var(--font-size-small);
    color: var(--primary-color-2);
    transform: translateX(3.5rem);
}

.toggle .labels {
    position: absolute;
    font-size: var(--font-size-small);
    left: 0;
    width: 100%;
    height: 100%;
}

.toggle .labels::after {
    content: attr(data-on);
    position: absolute;
    top: 0.25rem;
    right: 1rem;
    color: var(--primary-color-5);
    opacity: 1;
    transition: opacity 0.5s;
}

.toggle .labels::before {
    content: attr(data-off);
    position: absolute;
    top: 0.25rem;
    left: 1rem;
    color: var(--primary-color-5);
    opacity: 0;
    transition: opacity 0.5s;
}

.toggle input:checked~.labels::after {
    opacity: 0;
}

.toggle input:checked~.labels::before {
    opacity: 1;
}

.font-size-switch {
    position: relative;
    height:50px;
    display:flex;
    justify-content:space-around;
    align-items: center;
}

.font-size-switch label {
    padding: 1rem;
    z-index: 1;
    cursor: pointer;
    color: var(--primary-color-5);
}

#active-label {
    position: absolute;
    left: -1%;
    width: 34%;
    height: 50%;
    background: var(--primary-color-3);
    border: 1px solid var(--primary-color-4);
    border-radius: 12px;
    opacity: 0.5;
    transition: 0.5s left ease;
}

input[type="radio"] {
    display:none;
    cursor: pointer;
}