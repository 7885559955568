.headline {
    font-size: 22px;
    color: var(--primary-color-5);
}

.info1 {
    font-size: 12px;
    color: var(--primary-color-4);
}

.info2 {
    font-size: 13px;
    color: var(--primary-color-4);
    background-color: var(--primary-color-2);
    border-radius: 0.2rem;
    padding-inline: 1rem;
    display: flex;
    height: 4rem;
    align-items: center;
    vertical-align: middle;
}

.export-modal-today {
    height: 2.4rem;
    background-color: var(--primary-color-2);
    padding-inline: 0.5rem;
}

.export-modal-today, .export-modal-date {
    font-size: 14px;
    color: var(--primary-color-5);
}

.export-modal-today {
    width: 6rem;
    border-radius: 0.2rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.exportlabel {
    font-size: 14px;
    color: var(--primary-color-5);
}

#left:hover, #right:hover, #today:hover {
    background-color: var(--primary-color-3) !important;
}
.ticket-field{
    display: flex;
    align-items: center;
}
.ticket-number-field{
    width: 25%;
    background-color: var(--primary-color-2)!important;
    border-color: var(--primary-color-2)!important;
    -webkit-appearance: none;
;
}



