body {
    background-color: lightgray;
}

.content-wrapper{
    padding: 0;
}

@media (min-width: 992px) {
    .content-wrapper {
        display: grid;
        grid-template-columns: auto var(--resize-handler-width);
    }
}

.bg-primary-1{
    background: var(--primary-color-1);
}

.bg-primary-2{
    background: var(--primary-color-2);
}

.rounded-border-start{
    border-radius: 0.2rem 0 0 0.2rem;
}

.rounded-border-end{
    border-radius: 0 0.2rem 0.2rem 0;
}
.rounded-border-1{
    border-radius: 0.2rem;
}

.rounded-border-2 {
    border-radius: 0.5rem;
}

.button-effect-1:active {
    background-color: var(--active-color-1) !important;
}

.button-effect-2:active {
    background-color: var(--active-color-blue) !important;
}

.button-effect-3:active {
    background-color: var(--active-color-red) !important;
}

.button-effect-4:active {
    background-color: var(--active-color-green) !important;
}

.main-content{
    width:100%;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.02);
    overflow: auto;
}

.border-none {
    border: none;
}

.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.align-cell-items {
    vertical-align: middle;
    text-align: center;
}

.responsive-header {
    align-items: unset;
}

#navigation-toggler {
    display: flex;
}
