:root {
    --font-size-small: 12px;
    --font-size-default: 14px;
    --font-size-medium: 16px;
    --font-size-large: 18px;
}

.font-small {
    --font-size-small: 10px;
    --font-size-default: 12px;
    --font-size-medium: 14px;
    --font-size-large: 16px;
}

.font-large {
    --font-size-small: 14px;
    --font-size-default: 16px;
    --font-size-medium: 18px;
    --font-size-large: 20px;
}

@font-face {
    font-family: "Poppins Regular";
    src: url("../fonts/Poppins-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins Medium";
    src: url("../fonts/Poppins-Medium.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins SemiBold";
    src: url("../fonts/Poppins-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins Bold";
    src: url("../fonts/Poppins-Bold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins ExtraBold";
    src: url("../fonts/Poppins-ExtraBold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins ExtraLight";
    src: url("../fonts/Poppins-ExtraLight.ttf");
    font-weight: normal;
    font-style: normal;
}

.font-bold {
    font-family: "Poppins Bold";
}

.font-medium {
    font-family: "Poppins Medium";
}

.font-semi-bold {
    font-family: "Poppins SemiBold"
}

.font-regular {
    font-family: "Poppins Regular"
}

.font-extra-bold {
    font-family: "Poppins ExtraBold"
}

.font-extra-light {
    font-family: "Poppins ExtraLight"
}

.font-size-small {
    font-size: var(--font-size-small);
}

.font-size-default {
    font-size: var(--font-size-default);
}

.font-size-medium {
    font-size: var(--font-size-medium);
}

.font-size-large {
    font-size: var(--font-size-large);
}