@import "color.css";
@import "fonts.css";


.wrapper{
    height: 100%;
    display: flex;
    flex-direction: row;
}

.form-group label {
    color: var(--primary-color-5);
}

.form-widget input.form-control, .form-widget textarea.form-control{
    background: var(--primary-color-2);
}

.ts-wrapper.form-select{
    background-color: var(--primary-color-2);
    color: var(--primary-color-5);
}

.ea-new-form{
    margin-left: 2rem;
}

table {
    --bs-table-bg: transparent !important;
}

table.datagrid{
    margin-top: 4rem !important;
}

input{
    color: var(--primary-color-5);
}

.modal-container{
    justify-content: end;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    top: 11rem;
    margin-right: 3rem;
}

.content-top {
    height: 240px;
    background-image: url("../../public/images/header.jpg");
    background-size: cover;
}

.content-top.bos_date {
    background-image:
            linear-gradient(to right, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 50%),
            url("../../public/images/boardofshame/shame_background.jpg");
}

.content-title {
    font-weight: bolder;
    color: #FFFFFF;
    font-size: 3rem;
}

.content-title.bos_date {
    font-family: Luminari;
}

.container {
    position: absolute;
    bottom: 6.25rem;
    font-size: xx-large;
    width: fit-content;
}


th span, th a {
    float: left;
}

body {
    background: var(--primary-color-1);
}

thead th:nth-child(1) {
    width: 1rem;
}

thead {
    position: relative;
    bottom: 1.5rem;
}

tbody {
    text-align: left;
    font-size: small;
}

td.color {
    text-align: center;
}

td.name {
    color: var(--primary-color-5);
}

td.actions {
    display: flex;
    justify-content: right;
}

tbody tr {
    border: 0.2rem solid var(--primary-color-1);
    border-top: 0.1rem solid var(--primary-color-3);
    border-bottom: 0.1rem solid var(--primary-color-3);
    background: var(--primary-color-1);
}

button.action-edit, button.action-delete, button.action-detail {
    background-color: var(--primary-color-3);
    border: 0;
    margin-left: 0.35rem;
    height: 1.3rem;
    width: 1.3rem;
    padding-inline: 0.15rem;
    display: flex;
    align-items: center;
}


button.action-edit:hover, button.action-detail:hover {
    background-color: var(--accent-colors-blue);
}

button.action-delete:hover {
    background-color: var(--signal-red);
}


thead {
    text-align: center;
    border-top: 0.05rem solid var(--primary-color-3);
    border-bottom: 0.05rem solid var(--primary-color-3);
}

th.edit-delete {
    text-align: right;
    padding-bottom: 4px;
}

a.action-new.btn.btn-primary {
    background-color: var(--accent-colors-blue);
    margin-top: 1rem;
}

svg.true-icon {
    fill: var(--signal-green);
    height: 1.25rem;
    width: 1.25rem;
}

svg.edit-icon, svg.delete-icon {
    fill: var(--primary-color-5);
    width: 0.75rem;
    height: 0.75rem;
    align-items: center;
}

svg.false-icon {
    fill: var(--signal-red);
    height: 1.25rem;
    width: 1.25rem;
}

svg.action-icon {
    fill: var(--primary-color-5);
    height: 1rem;
    width: 1rem;
    padding-bottom: 0.25rem;

}

svg.color-icon {
    fill: var(--primary-color-5);
    height: 1.25rem;
    width: 1.25rem;
    padding-right: 0.3rem;
}

svg.color-dot {
    height: 1rem;
    width: 1rem;
    margin-top: 0.5rem;
    margin-left: -1.2rem;
    margin-right: 0.2rem;
}

.field-values {
    margin-right: 50rem;
    background-color: var(--primary-color-3);
}
.others {
   color: black;
}

.form-widget.color {
    display: flex;
    width: 8rem;
}

.field-textarea.form-group, .form-widget {
    width: 20rem;
}

.datagrid thead span {
    color: var(--primary-color-5);
}

.datagrid thead th {
    color: var(--primary-color-5);
}

.datagrid td {
    color: var(--primary-color-5);
}

.datagrid thead a {
    color: var(--primary-color-5);
}

.content-wrapper {
    max-width: 100% !important;
}

.ea-dark-scheme{
    --table-hover-cell-bg: none;
}

.col-form-label{
    display:none;
}

.archive-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.archive-modal-input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--main-text-color);
}

.archive-page-buttons, .restore-button {
    border: none;
    border-radius: 0.2rem;
    background-color: var(--primary-color-3);
    color: var(--main-text-color);
}

.archive-page-buttons:hover, .restore-button:hover, .archive-modal-button:hover, .archive-modal-button {
    background-color: var(--accent-colors-blue);
}

.restore-button {
    width: 1.5rem;
    height: 1.5rem;
}

.archive-page-buttons {
    width: 10rem;
    margin-left: 0.5rem;
    height: 2rem;
}

.archive-modal-button {
    border-radius: 0.2rem;
    border: none;
    margin-top: 1rem;
    height: 2rem;
    width: 8rem;
}

.archive-modal-date-picker {
    border: none;
    background-color: var(--primary-color-4);
    text-align: center;
    height: 2rem;
}

.archive-modal-headline-text {
    text-align: center;
    color: var(--main-text-color);
}

.field-boolean {
    margin-top: 2rem;
}

.ts-control, .form-control {
    color: var(--primary-color-5) !important;
}

.ea-edit-form{
    position: relative;
    padding-inline: 2rem;
}

.page-actions{
    padding: 2rem;
}

#modal-filters .modal-dialog {
    max-width: 31.25rem;
}

.form-check-label {
    color: var(--main-text-color);
}

#modal-filters .modal-body, #modal-filters .modal-header{
    background: var(--primary-color-1);
}

#widget-attributes:not(.form-check-input):not(.form-control) {
    background: var(--primary-color-3);
    color: var(--main-text-color);
}

.remember-me-label {
    color: var(--primary-color-4);
}
