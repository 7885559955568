.mr-0 {
    margin-right: 0;
}

.mt-0 {
    margin-top: 0;
}

.ml-0 {
    margin-left: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.m-0 {
    margin: 0;
}

.mr-1 {
    margin-right: var(--spacing-1);
}

.mb-1 {
    margin-bottom: var(--spacing-1);
}

.mt-1 {
    margin-top: var(--spacing-1);
}

.ml-1 {
    margin-left: var(--spacing-1);
}

.mr-2 {
    margin-right: var(--spacing-2);
}

.ml-2 {
    margin-left: var(--spacing-2);
}

.mt-2 {
    margin-top: var(--spacing-2);
}

.mb-2 {
    margin-bottom: var(--spacing-2);
}

.mt-3 {
    margin-top: var(--spacing-3);
}

.mr-3 {
    margin-right: var(--spacing-3);
}

.mb-3 {
    margin-bottom: var(--spacing-3);
}

.ml-3 {
    margin-left: var(--spacing-3);
}

.mr-4 {
    margin-right: var(--spacing-4);
}

.mb-4 {
    margin-bottom: var(--spacing-4);
}

.ml-4 {
    margin-left: var(--spacing-4);
}

.mt-4 {
    margin-top: var(--spacing-4);
}

.mr-5 {
    margin-right: var(--spacing-5);
}

.mb-5 {
    margin-bottom: var(--spacing-5);
}

.ml-5 {
    margin-left: var(--spacing-5);
}

.mt-5 {
    margin-top: var(--spacing-5);
}






