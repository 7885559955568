dl.datalist {
    display: flex;
    flex-direction: column;
    width: 68rem;
}

div.data-row {
    width: auto;
    height: auto;
    align-items: center;
}
.form-panel-body{
    position: relative;
}
.field-collection{
    position: relative;
    top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
}

.data-rowfield-textarea, .action-delete, .action-index, .action-edit, .data-rowfield-text, .data-rowfield-collection {
    color: var(--primary-color-5) !important;
}

.detail-actions {
    display: flex;
    flex-direction: row-reverse;
    margin-left: auto;
    gap: 1rem;
}
.field-value {
    background-color: var(--primary-color-2);
}

.last-two-fields-wrapper{
    display: flex;
    justify-content: space-between;
    margin: 4rem 0 0 2rem;
    gap: 3rem;
}

.field-value{
    width: 37vw;
    height: 3.5vh;
    margin-top: 0.5rem;
    line-height: 2.5;
    padding-left: 1vh;
    border-radius: 0.2rem;
}

.field-text{
    position: relative;
    top: 2.5vh;
}
.field-text dt{
    display: none;
}
.description-field{
    margin-top: 10vh;
}
.data-rowfield-text dt{
    display: none;
}
.data-rowfield-text{
    width: 81vw;
}
.data-rowfield-textarea{
    margin-left: 2rem;
}
.field-value-name{
    font-size: 1.25vw;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.detail-title-actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    margin: 3rem 3rem 3rem 2rem;
}

.data-rowfield-form_fieldset{
    display: none;
}

.action-delete, .action-index, .action-edit{
    background-color: var(--primary-color-2);
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 2px;
}

.btn-secondary{
    background: var(--primary-color-2);
    color: var(--primary-color-5);
}

.btn-secondary:hover{
    background: var(--primary-color-3) !important;
    color: var(--primary-color-5) !important;
}