.pl-1 {
    padding-left: var(--spacing-1);
}

.pr-1 {
    padding-right: var(--spacing-1);
}


.pl-2 {
    padding-left: var(--spacing-2);
}

.pr-2 {
    padding-right: var(--spacing-2);
}

.pl-3 {
    padding-left: var(--spacing-3);
}

.pr-3 {
    padding-right: var(--spacing-3);
}

.pl-4 {
    padding-left: var(--spacing-4);
}

.pr-4 {
    padding-right: var(--spacing-4);
}

.pl-5 {
    padding-left: var(--spacing-5);
}

.pr-5 {
    padding-right: var(--spacing-5);
}
