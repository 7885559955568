@import 'color.css';
@import 'fonts.css';

.logo {
    padding: 3rem 0 3rem 0;
    text-align: center;
}

.ambimax {
    height: 9rem;
    width: auto;
}

.sidebar, .sidebar-wrapper {
    background-color: var(--primary-color-1);
    width: 18.5vw;
}

.sidebar {
    max-width: var(--width);
    overflow: initial !important;
    padding: 0;
}

.sidebar-section {
    width: 18.5vw;
    color: var(--primary-color-5);
}

.menu {
    list-style-type: none;
    padding-left: 0.1rem;
    display: flex;
    flex-direction: column;
}

.menu-item.active, .menu-item:hover {
    background-color: var(--primary-color-2);
    width: 18.3vw;
}

.menu-item:hover .sidebar-icon {
    background-color: var(--primary-color-3);
}

.menu-item:hover .sidebar-icon.active, .sidebar-icon.active {
    background-color: var(--accent-colors-orange);
}

.menu-item:active {
    background-color: var(--active-color-2);
}

.sidebar-icon {
    height: 2.1rem;
    width: 2.1rem;
    background-color: var(--primary-color-2);
    border-radius: 50%;
    fill: var(--primary-color-5);
    padding-inline: 0.5rem;
}


.label {
    font-size: var(--font-size-medium);
    margin-left: 0.5rem;
    color: var(--primary-color-5);
}

.section {
    display: flex;
    pointer-events: none;
    align-items: center;
    height: 10px;
}

.bottom {
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    align-items: center;
}

.user {
    background-color: var(--primary-color-2);
    border-radius: 3rem;
    width: fit-content;
    padding: 0.5rem 1rem;
    margin-bottom: 10px;
}

.avatar {
    border-radius: 50%;
    vertical-align: 10%;
    height: 1.5rem;
    width: auto;
}

.user-name {
    color: var(--primary-color-5);
    font-size: var(--font-size-default);
}

.logout-button {
    visibility: hidden;
}

.user-wrapper:hover .logout-button {
    visibility: visible;
    background-color: var(--primary-color-2);
    height: 2rem;
    border: 0;
    width: 5rem;
    font-size: 12px;
    border-radius: 0.2rem;
    color: var(--primary-color-5);
}

.logout-button:hover {
    background-color: var(--accent-colors-blue);
}

.badge.badge-secondary{
    background: #FF4600;
    color: white;
    box-shadow: none;
}

.sidebar-item-click-area{
    display: flow;
    width: 18.5vw;
    padding: 0.5rem 1.2rem;
}